import React, { useState } from 'react'
import propTypes from 'prop-types'
import firebase from "gatsby-plugin-firebase"
import Cookies from 'js-cookie'
import validate from 'validate.js'

const SubscribeForm = (props) => {
    const [email, setEmail] = useState("")
    const [formMessage, setFormMessage] = useState("")
    const [errorMessage, setErrorMessage] = useState("")

    const { style, url } = props

    const onInputChange = e => {
        e.persist()
        e.target.classList.add("touched");

        setEmail(e.target.value)
    }

    const onFormSubmit = e => {
        e.preventDefault()

        const errors = validate({ email: email }, { email: { email: true }})

        if (errors == null || errors.length == 0) {
            var sendForm = firebase.functions().httpsCallable('sendSubscribeForm');
            var data = [{
                    "name": "email",
                    "value": email
                }]

            var cookie = Cookies.get("hubspotutk")

            sendForm({ "form": data, "url": url, "cookie": cookie })
            .then(result => {
                if (result.data.success) {
                    setFormMessage(result.data.result.inlineMessage)
                }
            })
            .catch(error => {
                console.error(error)
            })
        }
        else
        {
            setErrorMessage(errors[0])
        }
    }

    const form = (
        <>
            <div className="info">
                <h5>Get updates quick!</h5>
                <p>Spoiler alert: The next episode is where it gets really good. Sign up and see how we meet the deadline / don't get fired / save the princess.</p>
            </div>
            <div className="input-container">
                <label htmlFor="email">Email</label>
                <input id="email" name="email" type="email" onChange={onInputChange} required />
                { errorMessage }
            </div>
            <input className="submit-button" name="submit" type="submit" value="Subscribe" />
        </>
    )

    return (
        <form className="subscribe" style={style} onSubmit={ onFormSubmit }>
            {
                formMessage ? (<div dangerouslySetInnerHTML={{ __html: formMessage }} />) : form
            }
        </form>
    )
}

SubscribeForm.propTypes = {
    url: propTypes.string.isRequired
}

export default SubscribeForm