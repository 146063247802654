import React from "react"
import { Link } from "gatsby"

import SubscribeForm from '../components/subscribe-form'
import Layout from '../components/layout'

const stories = [
    {
        key: "Does my idea even make sense?",
        thumbnail: "../images/stories-illustration.png",
        excerpt: "Urbity hit a roadblock, namely that our biggest client wasn't paying us. Brian, the boss, had big plans for the company. If we could afford them.~Accounting is not his thing. But we're a software agency. Could we create a program to balance the books? Could we sell it? Or are we nuts? ",
        option: "Coming Soon"
    },
    {
        key: "The Limits of Our Brilliance",
        thumbnail: "../images/urbity2.jpg",
        excerpt: "The modern business owner deals with a seemingly infinite number of accounting issues. Software has the potential for seemingly infinite solutions. So we're good, right?~Well, Urbity's schedule doesn't allow for infinite solutions. Or even every element that we could personally use. Can we find items to cut without making our app irrelevant?"
    },
    {
        key: "Design 'n' Development",
        thumbnail: "../images/urbity6.jpg",
        excerpt: "We finally figured out what we can do in the span of 8 weeks. Now all we have to do is do it. This is the point where most good ideas hit the wall, especially with a tight deadline.~But this is Brian's idea, not a client's. And he keeps realizing tiny improvements that would make the app even better. We're just trying to keep him out of his own deadline's way."
    }
]

const StoriesPage = ({location}) => (
    <Layout colorTheme="secondary-a" bgColorTheme="blue" >
        <main id="stories">
            <section className="hero comp-padb blue">
                <div className="content extra" style={{ justifyContent: 'center' }}>
                    <div className="tagline column">
                        <h1>Watch Us Put Our<br />Feet in The Fire</h1>
                        <p>We're not the first agency to make bold claims about speed. We may, however, be the first to show you our process so you know we're for real.</p>
                        <p>Urbity's Story Series gives us a chance to show off, and you a chance to see the plan in motion...how we grapple with challenges to maintain our big ideas.</p>
                    </div>
                    <SubscribeForm url={ location.href } style={{ marginTop: '2rem', marginBottom: '2rem', maxWidth: 360 }} />
                </div>
            </section>

            <section className="white comp-pad">
                <div className="content column">
                    {
                        stories.map((story, index) => (
                            <div className="thumbnail-info in-pad " key={ story.key }>
                                <div className="thumbnail image-responsive round">
                                    <div className="wrapper">
                                        <div className="image" style={{ backgroundImage: `url(${story.thumbnail})` }} />
                                    </div>
                                </div>
                                <div className="info">
                                    <div className="header step">
                                        <h2>{ story.key }</h2>
                                    </div>
                                    <div className="info-seperator">
                                        { story.excerpt.split("~").map((p) => (
                                            <p key={ story.key }>{ p }</p>
                                        ))
                                        }
                                        <div className="options" style={{ opacity: 0.8 }}>
                                            <Link className="link" to="#" >Coming Soon</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </section>

            <section className="call-to-action secondary-b">
                <div className="content extra column">
                    <div className="header space-between">
                        <div className="title">
                            <h4>Let's Do It</h4>
                            <h3>Your idea is great.<br />Let's kickstart it.</h3>
                        </div>
                        <Link to="/hire-us" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>Work with us</span></Link>
                    </div>
                </div>
            </section>
        </main>
    </Layout>
)

export default StoriesPage;